import React, { useEffect, useContext } from 'react'
import { AppContext } from '../context/AppContext'
import '../styles//modules/sample-app-cocuhflix.scss'
import shotAll from '../assets/images/sample-app-couchflix/shot-all.png'
import shotCode from '../assets/images/sample-app-couchflix/shot-code.png'

const SampleAppCouchflix = () => {
  const context = useContext(AppContext)
  useEffect(() => {
    context.changeTheme('dark')
    return function cleanup() {
      context.changeTheme('light')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="page--sample-app-couchflix dark-gradient text-white pb-12">

        <div className="wrapper pt-6 pb-8">
          <h1 className="text-white">
            Couchflix: the sample application
          </h1>
          <p className="text-lg lg:text-3xl text-white font-light mb-9">
            Denis Rosa (<a target="_blank"  rel="noopener noreferrer" href="https://github.com/deniswsrosa">@deniswsrosa</a>) demonstrates how to build a Netflix-like web application called Couchflix, which features the ability to search for relevant media using the Couchbase Server JSON document database and its full text search API.
          </p>
          <div className="pt-8">
            <a href="https://www.youtube.com/watch?v=FYnke4o9aLw"
              className="btn-couchflix-transparent text-sm sm:text-base hover:no-underline text-white font-bold mr-4 sm:mr-8"
              target="_blank" rel="noopener noreferrer"
            >
              Watch the Talk
            </a>
            <a href="https://github.com/deniswsrosa/couchflix"
              className="inline-block text-sm sm:text-base pt-8 btn-arrow-right hover:no-underline text-white hover:text-white font-bold"
              target="_blank" rel="noopener noreferrer"
            >
              Browse the Code
            </a>
          </div>
        </div>

        <div className="w-full flex flex-row wrapper mt-6 order-2 sm:order-1 pr-2 lg:pr-12 pl-0 md:pl-0 lg:pl-0">
          <div className="w-1/2">
            <h3 className="divider-below">Technologies Used</h3>
            <ul>
              <li>Java</li>
              <li>Spring Data, Spring Boot</li>
              <li>HTML, CSS</li>
              <li>JavaScript, Bootstrap</li>
              <li>JSON, REST, Selenium</li>
              <li>Couchbase Server, Full Text Search API</li>
              <li>Chrome Driver, Google Image Service</li>
            </ul>
          </div>
          <div className="w-1/2">
            <h3 className="divider-below">Entities</h3>
            <ul>
              <li>Actor</li>
              <li>Company</li>
              <li>Country</li>
              <li>Credit</li>
              <li>Genre</li>
              <li>Language</li>
              <li>Movie</li>
              <li>MovieCollection</li>
            </ul>
          </div>
        </div>
      <div className="w-full flex flex-row wrapper mt-8 order-2 sm:order-1 pr-2 lg:pr-12 pl-0 md:pl-0 lg:pl-0">
        <div className="w-11/12">
          <a target='_blank' rel="noopener noreferrer" href='https://github.com/deniswsrosa/couchflix'>
            <img src={shotAll} alt='Couchflix Thumbnail' className="w-2/5 inline mr-3 border-2 border-blue-300"/>
          </a>
          <a target='_blank' rel="noopener noreferrer" href='https://github.com/deniswsrosa/couchflix'>
            <img src={shotCode} alt='Couchflix Thumbnail with Code' className="w-2/5 inline border-2 border-blue-300"/>
          </a>
        </div>
      </div>

    </div>
  )
}

export default SampleAppCouchflix
